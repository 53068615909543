import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        session_merchant_list: [],         //会话中的商家列表
        session_merchant_item: null,         //会话中的被选中的商家
        session_user_list: [],             //会话中私信用户列表
        session_user_item: null,             //会话中当前被选中的私信用户
        session_chat_content: [],          //当前会话内容
        session_chat_new_message:null,          //是否有新的消息，来监控这个值的变化，如果变化了，那么就是有新的值。
        session_chat_new_message_customer:null, //是否有新的消息，来监控这个值的变化，如果变化了，那么就是有新的值(主要用于客户管理的小窗口)
        shortcut_group_list: [],           //快捷话术分组类型
        shortcut_content_list: [],         //话术内容列表
        send_message_content_text: "",     //即将要被发送的文本框内容

        statistics_merchant_list: [],           //统计中的商家列表
        statistics_merchant_item: null,         //统计中的被选中的商家

        account_merchant_list: [],              //账号管理中的账号列表
        account_merchant_item: null,            //被选中的账号详情

    },
    mutations: {
        set_session_merchant_list(state, list) {
            state.session_merchant_list = list;
        },
        push_session_merchant_list(state, data) {
            state.session_merchant_item.push(data);
        },
        set_session_merchant_item(state, data) {
            state.session_merchant_item = data;
        },
        set_session_user_list(state, list) {
            state.session_user_list = list;
        },
        push_session_user_list(state, data) {
            state.session_user_list.push(data);
        },
        update_session_user_list(state,info){
            state.session_user_list[info.index] = info.data;
        },
        set_session_user_item(state, data) {
            state.session_user_item = data;
        },
        push_session_chat_content(state, data) {
            state.session_chat_content.push(data);
        },
        set_session_chat_content(state, data) {
            state.session_chat_content = data;
        },
        set_session_chat_new_message(state,data){
            state.session_chat_new_message = data;
        },
        set_session_chat_new_message_customer(state,data){
            state.session_chat_new_message_customer = data;
        },
        set_shortcut_group_list(state, list) {
            state.shortcut_group_list = list;
        },
        push_shortcut_group_list(state, data) {
            state.shortcut_group_list.push(data);
        },
        set_shortcut_content_list(state, list) {
            state.shortcut_content_list = list;
        },
        push_shortcut_content_list(state, data) {
            state.shortcut_content_list.push(data);
        },
        set_send_message_content_text(state, data) {
            state.send_message_content_text = data;
        },

        /**
         * 数据统计
         * @param state
         * @param list
         */
        set_statistics_merchant_list(state, list) {
            state.statistics_merchant_list = list;
        },
        push_statistics_merchant_list(state, data) {
            state.statistics_merchant_item.push(data);
        },
        set_statistics_merchant_item(state, data) {
            state.statistics_merchant_item = data;
        },

        /**
         * 账号管理
         * @param state
         * @param data
         */
        set_account_merchant_list(state, data) {
            state.account_merchant_list = data;
        },
        set_account_merchant_item(state, data) {
            state.account_merchant_item = data;
        }


    },
    actions: {
        set_session_merchant_list(context) {
            context.commit("set_session_merchant_list");
        },
        push_session_merchant_list(context) {
            context.commit("push_session_merchant_list");
        },
        set_session_merchant_item(context) {
            context.commit("set_session_merchant_item");
        },
        set_session_user_list(context) {
            context.commit("set_session_user_list");
        },
        push_session_user_list(context) {
            context.commit("push_session_user_list");
        },
        update_session_user_list(context) {
            context.commit("update_session_user_list");
        },
        set_session_user_item(context) {
            context.commit("set_session_user_item");
        },
        push_session_chat_content(context) {
            context.commit("push_session_chat_content");
        },
        set_session_chat_content(context) {
            context.commit("set_session_chat_content");
        },
        set_session_chat_new_message(context) {
            context.commit("set_session_chat_new_message");
        },
        set_session_chat_new_message_customer(context) {
            context.commit("set_session_chat_new_message_customer");
        },
        set_shortcut_group_list(context) {
            context.commit("set_shortcut_group_list");
        },
        push_shortcut_group_list(context) {
            context.commit("push_shortcut_group_list");
        },
        set_shortcut_content_list(context) {
            context.commit("set_shortcut_content_list");
        },
        push_shortcut_content_list(context) {
            context.commit("push_shortcut_content_list");
        },
        set_send_message_content_text(context) {
            context.commit("set_send_message_content_text");
        },

        set_statistics_merchant_list(context) {
            context.commit("set_statistics_merchant_list");
        },
        push_statistics_merchant_list(context) {
            context.commit("push_statistics_merchant_list");
        },
        set_statistics_merchant_item(context) {
            context.commit("set_statistics_merchant_item");
        },


        set_account_merchant_list(context) {
            context.commit("set_account_merchant_list");
        },
        set_account_merchant_item(context) {
            context.commit("set_account_merchant_item");
        }

    },
    modules: {}
})
