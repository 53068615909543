import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: {
      title: '登录-有客私信客服系统'
    },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '/home/session',
        name: 'Session',
        component: () => import('@/components/Session/Index.vue'),
        meta: {
          title: '私信私信消息会话-有客私信客服系统'
        },
      },
      {
        path: '/home/statistics',
        name: 'Statistics',
        component: () => import('@/components/Statistics/Index.vue'),
        meta: {
          title: '统计-有客私信客服系统'
        },
      },
      {
        path: '/home/customer',
        name: 'customer',
        component: () => import('@/components/User/Index.vue'),
        meta: {
          title: '客资管理-有客私信客服系统'
        },
      },
      {
        path: '/home/account',
        name: 'customer',
        component: () => import('@/components/Account/Index.vue'),
        meta: {
          title: '账号管理-有客私信客服系统'
        },
      },
      {
        path: '/home/setting',
        name: 'customer',
        component: () => import('@/components/Setting/Index.vue'),
        meta: {
          title: '系统设置-有客私信客服系统'
        },
      }
    ]
  },
]




const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
})

export default router
